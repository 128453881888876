.username-and-photo {
  z-index: 20;
  height: 48px;
  padding: 8px 0 0 0;
  &:hover {
    cursor: pointer;
  }
}

.username-and-photo:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(4px);
}

.dropdown-menu {
  z-index: 10;
  padding: 16px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 44px;
  right: 0px;
  background-color: var(--daybreakBlue9);
  width: 96px;
  transition: all 0.4s ease;

  &:hover {
    cursor: pointer;
  }

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.top-bar-item {
  margin: 0 20px 0 0;
  color: white;

  > span {
    font-size: 16px;
    &:hover {
      color: red;
      transition: all 0.4s ease;
    }
  }
}

.links-container {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
}

.social-icon {
  width: 24px;
  height: 24px;
}
.social-icon > svg {
  transition: all 0.3s ease;
  filter: brightness(.95);
}
.social-icon > svg:hover {
  filter: brightness(1.7);
}

.left-side-container{
  display: flex;
  align-items: center;
}