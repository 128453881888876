
// CARD021
div.drinks > div > svg > g > g:nth-child(1) > g > path{
  fill: hsla(263, 47%, 16%, 1);
}



// CARD019
// change color of EVERY rect (background surface) with one rule :) 
div.bangin rect
{
  fill: rgb(60, 22, 22)!important;
  fill: transparent!important;
  // background-color: hsla(263, 47%, 16%, 1)!important;
}

// CARD020 - Across the world
div.world
{
  // fill: rgb(60, 22, 22)!important;
  // background-color: hsla(197, 47%, 16%, 1);
  background-color: #194354;
}

// CARD024 - Workout
// change color of EVERY rect (background surface) with one rule :) 
div.workout rect
{
  // fill: rgb(60, 22, 22)!important;
  fill: none;
  // background-color: hsla(263, 47%, 16%, 1)!important;
}
#workout-id > g > g:nth-child(3) > g:nth-child(1) > g > g > g > path{
  fill:red;
}
#workout-id > g > g:nth-child(3) > g:nth-child(6) > g:nth-child(2) > g:nth-child(2) > path{
  stroke:blue;
}



// CARD032 "MONEY"
div.money > div > svg > g > g:nth-child(1) > g > path{
  fill: transparent !important;
}






// .gradientBcgAnimation1.gradientBcgAnimation1 {
//   background: linear-gradient(323deg, #ff0000, #ffea00, #82ff00, #00f6ff, #9400ff, #ff00fa);
//   background-size: 1200% 1200%;

//   -webkit-animation: gradientBcgAnimation 14s ease infinite;
//   -moz-animation: gradientBcgAnimation 14s ease infinite;
//   animation: gradientBcgAnimation 14s ease infinite;
// }

.gradientBcgAnimation2.gradientBcgAnimation2.gradientBcgAnimation2 {
  background: linear-gradient(323deg, #ff0000, #82ff00, #00f6ff, #9400ff, #ff00fa);
  background-size: 1000% 1000%;

  -webkit-animation: gradientBcgAnimation 14s ease infinite;
  -moz-animation: gradientBcgAnimation 14s ease infinite;
  animation: gradientBcgAnimation 14s ease infinite;
}

.gradientBcgAnimation2-radial.gradientBcgAnimation2-radial.gradientBcgAnimation2-radial {
  // background: radial-gradient(at center, #ff0000, #82ff00, #00f6ff, #9400ff, #ff00fa);
  // background: radial-gradient(at center,  #9400ff, #ff00fa, #00f6ff,);
  // // background-size: 400% 400%;
  background: rgb(255,239,0);
background: radial-gradient(circle, rgba(255,239,0,1) 0%, rgba(240,0,255,1) 50%, rgba(0,42,255,1) 100%);
background-size: 150% 150%;
background-position: 50% 45%;
  background-origin: center center;
  transform-origin: center center;
  -webkit-animation: gradientBcgAnimation_radial 4s ease infinite;
  -moz-animation: gradientBcgAnimation_radial 4s ease infinite;
  animation: gradientBcgAnimation_radial 4s ease infinite;
}


// .gradientBcgAnimation3.gradientBcgAnimation3.gradientBcgAnimation3 {
//   background: linear-gradient(323deg, #ff0000, #00f6ff, #9400ff, #ff00fa);
//   background-size: 800% 800%;

//   -webkit-animation: gradientBcgAnimation 14s ease infinite;
//   -moz-animation: gradientBcgAnimation 14s ease infinite;
//   animation: gradientBcgAnimation 14s ease infinite;
// }


// universal coordinates for all
@-webkit-keyframes gradientBcgAnimation {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}
@-moz-keyframes gradientBcgAnimation {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}
@keyframes gradientBcgAnimation {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}

// 222 universal coordinates for all
// @-webkit-keyframes gradientBcgAnimation_radial {
//   0%{background-size: 0% 0%}
//   50%{background-size: 100% 100%}
//   100%{background-size: 0% 0%}
// }
// @-moz-keyframes gradientBcgAnimation_radial {
//   0%{background-size: 0% 0%}
//   50%{background-size: 100% 100%}
//   100%{background-size: 0% 0%}
// }
// @keyframes gradientBcgAnimation_radial {
  // 0%{background-position: 0% 0%}
  // 50%{background-position: 100% 100%}
  // 100%{background-position: 0% 0%}
  // 0%{transform: scale(1)}
  // 50%{transform: scale(3)}
  // 100%{transform: scale(1)}
  // 0%{  background: radial-gradient(at center, #ff0000, #82ff00, #00f6ff, #9400ff, #ff00fa)}
  // 50%{  background: radial-gradient(at center, #82ff00, #00f6ff, #9400ff, #ff00fa, #ff0000)}
  // 100%{  background: radial-gradient(at center, #ff0000, #82ff00, #00f6ff, #9400ff, #ff00fa)}; 
  // 0%{  filter: brightness(1)}
  // 50%{  filter: brightness(1.5)}
  // 100%{  filter: brightness(1)}
  //   0%{background-size: 100% 100%; background-position: 50% 50%}
  // 50%{background-size: 150% 150%; background-position: 50% 50%}
  // 100%{background-size: 100% 100%; background-position: 50% 50%}
  //   0%{filter: hue-rotate(-40deg) brightness(1)}
  // 50%{filter: hue-rotate(10deg) brightness(1)}
  // 100%{filter: hue-rotate(-40deg) brightness(1)}
  //   0%{filter: hue-rotate(180deg)}
  // 50%{filter: hue-rotate(0deg)}
  // 100%{filter: hue-rotate(180deg)}
  
// }



@keyframes wobble {
  33% {
    transform: rotate(5deg) scale(0.5);
  }
  66% {
    transform: rotate(-5deg) scale(0.65);
  }
}

@keyframes wobble {
  0% {
    transform: rotate(5deg) scale(0.5);
    opacity:0;
  }
  50% {
    transform: rotate(-5deg) scale(0.6);
    opacity:1;
  }
  100% {
    transform: rotate(5deg) scale(0.5);
    opacity:0;
  }
}