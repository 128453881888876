.pipe{
  margin: 0 8px;
}

.artist{
  display: block;
  color: gray;
  font-size: 14px;
  // white-space: nowrap;
}

