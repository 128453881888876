.pagination-wrapper{
  margin: 0 0 40px 0;
}

.ant-pagination-total-text{
  // margin-right: 16px;
  display: block;
  color: black;
}

.ant-pagination-item{
  width:60px;

  &:hover{
    filter: brightness(.95)
  }
  > a{
    // color: red;
    font-weight: 500;
    font-size: 16px;

    // &:active, &:focus &:hover, &:focus-visible, &:focus-within, &:visited {
    //   color: white!important;
    //   border-color: black!important;
    // }
  }
}

.ant-pagination-item-active{
  background-color: red;
  border-color: rgb(114, 0, 0);

  // &:active, &:focus &:hover, &:focus-visible, &:focus-within, &:visited {
  //   color: white!important;
  //   border-color: black!important;
  // }
  // &:hover{
  //   color: white!important;
  //   border-color: black!important;
  // }
  > a{
    color: white;
    // font-weight: 900;
    // font-size: 16px;
    // &:active, &:focus &:hover, &:focus-visible, &:focus-within, &:visited {
    //   color: white!important;
    //   border-color: black!important;
    // }
    // &:hover{
    //   color: white!important;
    //   border-color: black!important;
    // }
  }
}


.ant-pagination-item-active:focus, .ant-pagination-item-active:hover{
  // color: white!important;
  // background-color: rgb(174, 0, 0);
  border-color: rgb(255, 106, 106)!important;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a{
  color: white!important;
  // border-color: rgb(255, 106, 106)!important;
}