.page-title {
  font-size: 36px;
  padding: 32px 0 0 0;
}

.text-block {
  > h2 {
    font-size: 24px;
    padding: 0 0 8px 0;
    font-weight: 700;
  }
  p{
    font-size: 18px;
    line-height: 1.6;
    padding: 0 0 24px 0;
  }
  padding: 16px 0;
  max-width: 450px;
}
