/*body{*/
/*  background-color: rgba(0, 0, 0, .8);*/
/*}*/

.container {
  /*height: 500px;*/
  /*width: 300px;*/
  width: 100%;
  height: 100%;
  background: #000;
  text-align: center;
  overflow: hidden;

  border-radius: 8px;
}

.container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.scene {
  /*width: 100%;*/
  /*height: 100%;*/
  display: inline-block;
  /* vertical-align: middle; */
  perspective: 8px;
  perspective-origin: 50% 50%;
  position: relative;
  /* bottom: 250px; */
}

.wrap {
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: move 22s infinite linear;
  /* animation-fill-mode: forwards; */
}

.wall {
  /*background: url(space1.jpg);*/
  background: url('../images/space4.jpg');
  background-size: cover;
  filter: brightness(2);
  /* filter: contrast(5);*/
  /* filter: brightness(10) grayscale(0); */
  /* filter: grayscale(10); */

  /* filter: grayscale(0) contrast(3) ; */

  /* filter: saturate(1); */
}

.wrap:nth-child(2) {
  animation: move 22s infinite linear;
  animation-delay: 11s;
}

.wall {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: fade 22s infinite linear;
  /* animation-delay: 0s; */
}

.wrap:nth-child(2) .wall {
  animation-delay: 11s;
}

.wall-right {
  transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
  transform: rotateY(-90deg) translateZ(500px);
}

/* .wall-top {
  transform: rotateX(90deg) translateZ(500px);
} */

/* .wall-bottom {
  transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
  transform: rotateX(180deg) translateZ(500px);
} */

@keyframes move {
  0% {
    transform: translateZ(-500px) rotate(0deg);
  }
  100% {
    transform: translateZ(500px) rotate(0deg);
  }
}


@keyframes fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
