$light: 0.05;
$rpm: 10;

$background: #ccc;
$label: #ebd000;
$black: #0a0a0a;
$white: #fff;
$t: rgba($white, 0);

$width: 300px;
$height: $width*2;

// Lighten
$l1: rgba($white, $light * 1);
$l2: rgba($white, $light * 2);
$l3: rgba($white, $light * 3);
$l4: rgba($white, $light * 4);
$l5: rgba($white, $light * 5);

// Darken
$d1: rgba($black, $light * 1);
$d2: rgba($black, $light * 2);
$d3: rgba($black, $light * 3);
$d4: rgba($black, $light * 4);
$d5: rgba($black, $light * 5);

.cd-wrapper{
  position: relative;
  top: 4.1%;
  width: 150%;
  height: 91.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disc {
	// display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	animation: spin (60s / $rpm) linear infinite;
	box-shadow: 0 0 4em white;

	@keyframes spin {
		to {
			transform: rotate(1turn);
		}
	}
}

.cd {
	background-image: radial-gradient(
			circle,
			$background 0,
			$background 10%,
			$d4 0,
			$d4 11.5%,
			$l5 0,
			$l5 17%,
			$d1 0,
			$d1 17.5%,
			$l5 0,
			$l5 20%,
			$d1 0,
			$d1 20.5%,
			$l5 0,
			$l5 25%,
			$d4 0,
			$d4 30%,
			$t 0,
			$t 69.5%,
			$d3 0,
			$d4 100%
		),
		conic-gradient(
			#ffffff,
			#f5e1f6,
			#ffefd1,
			#fefec5,
			#c8c8c8,
			#8a8a8a,
			#605859,
			#704864,
			#3b49ac,
			#31c8de,
			#6cd399,
			#d0d0d0,
			#fcfcfc,
			#ffffff,
			#ffffff,
			#eafae9,
			#a3ffef,
			#98bcea,
			#9997a4,
			#868686,
			#767676,
			#f0ed5b,
			#e2a3a7,
			#d4d4d4,
			#ffffff,
			#ffffff
		);
}
