.header-labels{
  // padding: 0 0 0 16px;
  display: flex;
}
.email__input-wrapper{
  // padding: 0 16px;
  .ant-input-affix-wrapper .ant-input:not(:first-child){
    padding-left: 8px;
  }
}

.email__enter-your-email{
  font-size: 24px;
  color: white;
  padding: 0 0 16px 0;
  line-height: 1.2;
}

.email__input{
  height: 48px;

  .ant-input{

    font-size: 22px;
  }
  // margin: 0 16px;

}

.email__privacy{
  font-size: 12px;
  color: rgb(196, 196, 196);
  line-height: 1.2;
  padding: 12px 0 0 0;
}

// #root > div > div.pagewrapper.email > div.email__input-wrapper > span > input

#Capa_1{
  width: 16px;
}