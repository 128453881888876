// https://www.font-converter.net/en
@font-face {
  font-family: "Zrnic";
  src: url("./playstation-font/zrnic.eot"); /* IE9 Compat Modes */
  src: url("./playstation-font/zrnic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./playstation-font/zrnic.otf") format("opentype"), /* Open Type Font */
    url("./playstation-font/zrnic.svg") format("svg"), /* Legacy iOS */
    url("./playstation-font/zrnic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./playstation-font/zrnic.woff") format("woff"), /* Modern Browsers */
    url("./playstation-font/zrnic.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}