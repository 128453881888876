// ANTD FULL CSS (for development while adding components):
//@import '~antd/dist/antd.css';

// font-face custom fonts:
@import './fontface.scss';
// @import './NBInternationalProEditionWeb/stylesheet.css';

// ANTD PARTIALS:
@import '~antd/es/typography/style/index.css';
@import '~antd/es/style/index.css';
// @import '~antd/es/style/v2-compatible-reset.css';
@import '~antd/es/notification/style/index.css';
@import '~antd/es/modal/style/index.css';
@import '~antd/es/input/style/index.css';
@import '~antd/es/button/style/index.css';
@import '~antd/es/form/style/index.css';
@import '~antd/es/spin/style/index.css';
@import '~antd/es/icon/style/index.css';
@import '~antd/es/layout/style/index.css';
@import '~antd/es/grid/style/index.css';
@import '~antd/es/message/style/index.css';
@import '~antd/es/pagination/style/index.css';

//@import url('https://fonts.googleapis.com/css?family=Big+Shoulders+Text|Chewy|Inria+Serif|Lalezar|Unica+One&display=swap');

:root {
  --daybreakBlue1: #e6f7ff;
  --daybreakBlue2: #bae7ff;
  --daybreakBlue3: #91d5ff;
  --daybreakBlue4: #69c0ff;
  --daybreakBlue5: #40a9ff;
  --daybreakBlue6: #1890ff;
  --daybreakBlue7: #096dd9;
  --daybreakBlue8: #0050b3;
  --daybreakBlue9: #003a8c;
  --daybreakBlue10: #002766;

  --gray1: #ffffff;
  --gray2: #fafafa;
  --gray3: #f5f5f5;
  --gray4: #e8e8e8;
  --gray5: #d9d9d9;
  --gray6: #bfbfbf;
  --gray7: #8c8c8c;
  --gray8: #595959;
  --gray9: #262626;
  --gray10: #000000;

  --dustRed5: #ff4d4f;
  --dustRed6: #f5222d;
  --dustRed7: #cf1322;
  --dustRed8: #a8071a;
}

.App {
  overflow-x: hidden;
}

// COLOR PICKER: https://ant.design/docs/spec/colors/

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight:500;
}
body,
#root {
  // font-family: 'NB International Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;


  // font-weight: 500;
  // letter-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

button {
  background-color: transparent;
}

//LARGE HEADING INSIDE TOP-RIGHT-CORNER POPUP NOTIFICATION on "/selected" PAGE"
div.ant-notification-notice-message {
  color: white;
  font-weight: 600;
  font-size: 22px !important;
}

// NEW SELECTORS
div.ant-notification-notice-description,
div.ant-notification-notice-message {
  color: white !important;
}

div.ant-notification-notice-description {
  font-size: 15px !important;
}

div.ant-notification-notice.ant-notification-notice-closable {
  // SURFACE DIV:
  background-color: black !important;
  position: relative !important;
  padding: 16px;
}
a.ant-notification-notice-close
  > span.ant-notification-close-x
  > i.anticon.anticon-close.ant-notification-close-icon
  > svg
  > path {
  color: white !important;
}

span.ant-input-wrapper.ant-input-group {
  max-width: 505px;
  margin: 0 8px 8px 0;
}

// EMAIL SUBMIT BUTTON:
button.ant-btn-primary {
  background-color: var(--dustRed6);
  border: var(--dustRed6);
}
button.ant-btn-primary:hover {
  background-color: var(--dustRed8);
  border: var(--dustRed8);
}

button.ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
  width: 110px;
  height: 44px;
}

.ant-spin-dot-item {
  background-color: red;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  margin-top: 8px;
  margin-bottom: 24px;
}

.ant-modal-body {
  padding-bottom: 0;
}

//Modal input Labels: To and from labels:
div.ant-modal-body > form > span {
  font-size: 16px;
}
.ant-modal-footer {
  padding-top: 0;
}

// INCREASE SIZE OF TWO INPUT FIELDS ON BLACK MODAL:
input.ant-input.ant-input-lg {
  height: 44px;
  font-size: 20px;
}

// INCREASE SIZE OF 'CLEAR' X ICON AT FAR RIGHT SIDE OF INPUT FIELD:
i.anticon.anticon-close-circle.ant-input-clear-icon {
  font-size: 18px;
}

// REMOVE RED FILL (CHANGE COLOR OF RED STROKE) OF BOTTOM BADGE:
#root > div > div > a > div > svg > rect {
  stroke: #000;
}

.noselect {
  //-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  // user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

//ANTD MESSAGE (SMALL TOP PANE)
div.ant-message-notice-content {
  //background-color: var(--gray10);
  background-color: rgba(0, 0, 0, 0.75);
  color: var(--gray1);
  padding: 12px 8px;
  width: 100%;
}

//ANTD MESSAGE FONT-SIZE "Tap the card..."
.ant-message {
  font-size: 16px;
}

// MESSAGE: COLOR OF LEFT-SIDE CIRCLE-i ICON:
i.anticon-info-circle > svg[data-icon='info-circle'] {
  fill: var(--dustRed6);
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.85);
  }
  to {
    transform: rotate(359deg) scale(0.85);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.85);
  }
  to {
    transform: rotate(359deg) scale(0.85);
  }
}

// mailchimp email form:
.email-section-wrapper{
  
  margin: 40px 0 0 0;
  border-top: 1px solid rgb(162, 162, 162);

  h3{
    max-width: 400px;
    text-align: center;
    font-size: 28px;
    padding: 48px 0 24px 0;
    line-height: 1.15;
  }

  .custom-form-wrapper{
    width: 100%;

    .status-wrapper{
      text-align: center;
      font-size: 18px;

      > span{
        display: inline-block;
        margin: 0 0 10px 0;
      }
    }
  }
  .custom-form-inner {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 8px 0;

    input[type='email'].email-text-input {
      flex: 1 1 350px;
      max-width: 350px;
      height: 44px;
      padding: 0 12px;
      margin: 0 0 0 16px;
      color: rgb(99, 99, 99);
      padding: 0 0 0 12px;
      font-size: 20px;
      font-weight: 400;
      border: 1px solid rgb(176, 176, 176);

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: silver;
        font-size: 20px;
        font-weight: 300;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: silver;
        font-size: 20px;
        font-weight: 300;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: silver;
        font-size: 20px;
        font-weight: 300;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: silver;
        font-size: 20px;
        font-weight: 300;
      }
    }
    button {
      position: relative;
      right: 1px;
      background-color: red;
      color: white;
      width: 100px;
      min-width: 80px;
      max-width: 100px;
      height: 44px;
      font-size: 18px;
      font-weight: 300;
      margin: 0 16px 0 0;
      border: none;
      text-align: center;
      transition: all 0.4s ease;

      &:hover {
        background-color: rgb(187, 0, 0);
      }
    }

    .sending,
    .error,
    .success{
      text-align: center;
    }

  }
}
